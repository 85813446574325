var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialog,"width":"1200px","persistent":""}},[_c('v-card',{staticClass:"px-2 pb-2 pt-4"},[_c('v-card-text',{staticClass:"px-3 pt-4"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('h2',[_vm._v("Configuração da Renegociação de MRR")])])],1),(_vm.productsList.length)?_c('v-data-table',{key:"index",attrs:{"footer-props":{ itemsPerPageOptions: [5, 10] },"headers":_vm.headers,"items":_vm.products},scopedSlots:_vm._u([{key:"item.product_type",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.getProductFromType(item.product_type).name)+" ")])],1)]}},{key:"item.total_mrr",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.parseCurrencyDefault(item.total_mrr))+" ")])],1)]}},{key:"item.current_slots",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm._f("localeString")(item.slots,0))+" ")])],1)]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.product_type === 'sms' ? _vm.parseCurrencySms(item.total_mrr / item.slots) : _vm.parseCurrencyDefault(item.total_mrr / item.slots))+" ")])],1)]}},{key:"item.final_val",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('span',{class:_vm.isBigger(item) ? 'text-plus' : 'text-minus'},[_c('mf-icon',{attrs:{"size":"15","icon":_vm.isBigger(item) ? 'north' : 'south',"color":_vm.isBigger(item) ? '#003b02' : '#7c0e0e'}}),_vm._v(" "+_vm._s(_vm.parseCurrencyDefault(_vm.productsList[_vm.getIndex(item)].renegociationValue * item.slots))+" ")],1)])],1)]}},{key:"item.renegociationValue",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"money":"","precision-money":item.product_type === 'sms' ? 4 : 2},model:{value:(_vm.productsList[_vm.getIndex(item)].renegociationValue),callback:function ($$v) {_vm.$set(_vm.productsList[_vm.getIndex(item)], "renegociationValue", $$v)},expression:"productsList[getIndex(item)].renegociationValue"}})]}}],null,true)}):_vm._e(),_c('v-divider',{staticClass:"mx-2"})],1),_c('v-card-actions',{staticClass:"my-2"},[_c('v-spacer'),_c('mf-action-buttons',{staticClass:"mr-1",attrs:{"primary-button":{
          text: 'Atualizar valores',
          action: _vm.saveDiscount,
          isVisible: true,
          isDisabled: _vm.isQuantityValid
        },"extra-button":{
          text: 'Cancelar',
          action: _vm.close,
          isVisible: true
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }