<template>
  <v-dialog :value="dialog" width="1200px" persistent>
    <v-card class="px-2 pb-2 pt-4">
      <v-card-text class="px-3 pt-4">
        <v-row align="center">
          <v-col cols="4">
            <h2>Configuração da Renegociação de MRR</h2>
          </v-col>
        </v-row>
        <v-data-table v-if="productsList.length" key="index" :footer-props="{ itemsPerPageOptions: [5, 10] }" :headers="headers" :items="products">
          <template v-slot:[`item.product_type`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ getProductFromType(item.product_type).name }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.total_mrr`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ parseCurrencyDefault(item.total_mrr) }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.current_slots`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.slots | localeString(0) }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.unit`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.product_type === 'sms' ? parseCurrencySms(item.total_mrr / item.slots) : parseCurrencyDefault(item.total_mrr / item.slots) }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.final_val`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                <span :class="isBigger(item) ? 'text-plus' : 'text-minus'">
                  <mf-icon size="15" :icon="isBigger(item) ? 'north' : 'south'" :color="isBigger(item) ? '#003b02' : '#7c0e0e'"></mf-icon>
                  {{ parseCurrencyDefault(productsList[getIndex(item)].renegociationValue * item.slots) }}
                </span>
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.renegociationValue`]="{ item }">
            <field-toggle v-model="productsList[getIndex(item)].renegociationValue" money :precision-money="item.product_type === 'sms' ? 4 : 2"></field-toggle>
          </template>
        </v-data-table>
        <v-divider class="mx-2" />
      </v-card-text>
      <v-card-actions class="my-2">
        <v-spacer />
        <mf-action-buttons
          class="mr-1"
          :primary-button="{
            text: 'Atualizar valores',
            action: saveDiscount,
            isVisible: true,
            isDisabled: isQuantityValid
          }"
          :extra-button="{
            text: 'Cancelar',
            action: close,
            isVisible: true
          }"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep } from 'lodash'
import { allowedDateCrmActions } from '../../../helpers/crm'
import { DateTime } from 'luxon'
import { parseCurrency, productsInformation } from '@/mixin'
export default {
  components: {
    FieldToggle: () => import('@/components/atomic-components/atoms/FieldToggle.vue')
  },
  mixins: [parseCurrency, productsInformation],
  props: {
    dialog: {
      type: Boolean,
      default: () => false
    },
    products: {
      type: Array,
      default: () => []
    },
    clientId: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      productsList: [],
      confirm: false,
      date_discount: DateTime.now().toISODate(),
      disabled: false,
      contract_link: '',
      observation: ''
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID Produto',
          value: '_id',
          align: 'center'
        },
        {
          text: 'Produto',
          value: 'product_type',
          align: 'center'
        },
        {
          text: 'Total MRR',
          value: 'total_mrr',
          align: 'center'
        },
        {
          text: 'Quantidade Atual',
          value: 'current_slots',
          align: 'center'
        },
        {
          text: 'Valor Unitário',
          value: 'unit',
          align: 'center',
          width: '15%'
        },
        {
          text: 'Novo Valor Unitário',
          value: 'renegociationValue',
          align: 'center',
          width: '15%'
        },
        {
          text: 'Novo Valor Total MRR',
          value: 'final_val',
          align: 'center',
          width: '15%'
        }
      ]
    },
    isQuantityValid() {
      const valid = this.products.filter((product, index) => {
        return this.productsList?.[index]?.renegociationValue === 0
      })
      return !!valid.length
    }
  },
  mounted() {
    if (this.products.length) {
      let products_copy = cloneDeep(this.products)
      this.productsList = products_copy.map(item => {
        return {
          ...item,
          renegociationValue: 0
        }
      })
    }
  },
  methods: {
    /**
     * Dates allowed in calendar display
     * @param {*} val
     * @return {Bollean}
     */
    allowedDates(val) {
      return allowedDateCrmActions(val)
    },
    close() {
      this.$emit('update:dialog', false)
    },
    isBigger(item) {
      return this.productsList[this.getIndex(item)].renegociationValue > item.total_mrr / item.slots
    },
    async saveDiscount() {
      const variables = {
        sale: {
          type: 'discount',
          items: this.productsList.map(item => {
            return {
              mrr_renegotiation: item.renegociationValue * item.slots,
              sale_id: item.sale_id,
              name: this.getProductFromType(item.product_type).name,
              index: +item.index,
              type: item.product_type,
              quantity: item.slots,
              total_value: item.total_mrr,
              old_unit_value: item.total_mrr / item.slots,
              old_total_value: item.total_mrr
            }
          })
        }
      }
      this.$emit('click', variables)
    },
    getIndex(val) {
      return this.products.indexOf(val)
    }
  }
}
</script>

<style scoped>
.text-minus {
  color: #7c0e0e;
  border-radius: 10px;
  padding: 8px;
  background-color: rgba(185, 17, 17, 0.2);
}
.text-plus {
  color: #003b02;
  border-radius: 10px;
  padding: 8px;
  background-color: rgba(73, 216, 7, 0.5);
}
</style>
